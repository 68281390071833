// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
var envs = {
  arg : { 
    production: false,
    shareUrl : 'https://qa.alax.tech/share',
    api_url:'https://qa.cuponeala.com',
    countryId: 'AR',
    codigo_pais: '+54',
    pricing: true,
    customerUrl : {
      login: "https://testing.cuponeala.com/",
      register: "https://testing.cuponeala.com/clientes/adherir"
    },
    testimonies : false,
    marketplace: true,
    botmaker: 'o6L7WbuVFsRU',
    whatsapp: '541165443366',
    commercialEmail: "contacto@cuponeala.com", // Mexico/Latam = kam@cuponeala.com
    commercial: {
      email : "contacto@cuponeala.com",
      number: "+54 11 6544-3366",
      schedule : "(lunes a viernes de 09 a 13hs)"
    },
    price: [
      {type: "MENSUAL", freetrial: true, price: 1500, id: 4},
      {type: "MENSUAL", price: 1500, id: 3},
      {type: "SEMESTRAL", price: 1275, id:10,  discount: 15, single_payment: 7650},
      {type: "ANUAL", price: 1125, id: 13, discount: 25, single_payment: 13500},
      // PLUS
      {type: "MENSUAL", id: 6, price: 2250},
    ],
    testimony_list: [
      {
        name: 'Jorge Rojas', 
        comment: 'Aumento de Ventas ¡a Bajo costo y Sin Comisión! 5 ventas al mes pagan automáticamente el servicio. ', 
        local_avatar: 'puppies', 
        local_name: 'Puppies'
      }
    ],
    zone: { lat: -34.60376, lng: -58.38162},
    bannerColor: "#ff465b",
    amplitudeInstance : "122042d439d5370136372579251500d1",
    gaId: 'G-821XEYEKDV',
    lang: 'es'
},
ury : {
  production: false,
  shareUrl : 'https://app.alax.tech/share',
  api_url:'https://app.cuponeala.com',
  countryId: 'UY',
  pricing: false,
  customerUrl : {
    login: "https://ury.cuponeala.com/",
    register: "https://ury.cuponeala.com/clientes/adherir"
  },
  testimonies : false,
  marketplace: false,
  botmaker: null,
  whatsapp: "5491122668414",
  commercialEmail: "kam.latam@cuponeala.com",
  commercial: {
    email : "kam@cuponeala.com",
    number: "+54 9 11 2266-8414",
    schedule : "(lunes a viernes de 09 a 18hs)"
  },
  price: [],
  testimony_list: [],
  zone: { lat: -34.90328, lng: -56.18816},
  bannerColor: "#ff465b",
  amplitudeInstance : "122042d439d5370136372579251500d1",
  gaId: 'G-NPD189WRK7',
  lang: 'uy'
},
mex: {
  production: false,
  shareUrl : 'https://qa.alax.tech/share',
  api_url:'https://qa.cuponeala.com',
  countryId: 'MX',
  pricing: false,
  customerUrl : {
    login: "https://testmx.cuponeala.com/",
    register: "https://testmx.cuponeala.com/clientes/adherir"
  },
  marketplace: true,
  testimonies : false,
  commercialEmail: "kam.latam@cuponeala.com",
  botmaker: null,
  whatsapp: "5491122668414",
  commercial: {
    email : "kam.latam@cuponeala.com",
    number: "+54 11 6544-3366",
    schedule : ""
  },
  price: [],
  testimony_list: [],
  zone: { lat: 19.432608, lng: -99.133209},
  bannerColor: "#fb2853",
  amplitudeInstance : "b9d4102f98a405342e06f86c5f3bb0aa",
  gaId: 'G-821XEYEKDV',
  lang: 'es'
},
latam: {
  production: false,
  shareUrl : 'https://app.alax.tech/share',
  api_url:'https://app.cuponeala.com/consumer',
  api_url_local: 'https://app.cuponeala.com/consumer',
  countryId: 'LATAM',
  pricing: false,
  customerUrl : {
    login: "https://mex.cuponeala.com/",
    register: "https://mex.cuponeala.com/clientes/adherir"
  },
  marketplace: false,
  testimonies : false,
  commercialEmail: "kam.latam@cuponeala.com",
  botmaker: null,
  whatsapp: "5491122668414",
  commercial: {
    email : "kam.latam@cuponeala.com",
    number: "+54 9 11 2266-8414",
    schedule : ""
  },
  price: [],
  testimony_list: [],
  zone: { lat: 19.432608, lng: -99.133209},
  bannerColor: "#fb2853",
  amplitudeInstance : "b9d4102f98a405342e06f86c5f3bb0aa",
  gaId: 'G-821XEYEKDV',
  lang: 'es'
}
}


var baseUrl = "https://cuponeala.com";
try {
  if (window){
    const parsedUrl = new URL(window.location.href);
    baseUrl = parsedUrl.origin;
  } 
}catch(err){

}

var amb = "arg";
if (baseUrl.includes('uruguay')){
  amb = "ury";
}else if (baseUrl.includes('testmex')){
  amb = "mex";
}else if (baseUrl.includes('latam')){
  amb = "latam"
} else if (baseUrl.includes('alax.tech')) {
  envs.arg.marketplace = false;
  envs.arg.gaId = 'G-YXVZW9CCS8';
}

var ambiente = envs[amb];
ambiente.sites = {
  ar: "https://testarg.cuponeala.com",
  mx:"https://testmex.cuponeala.com",
  latam:"https://consumer.cuponeala.com",
  uy:"https://consumer.cuponeala.com"
}

export const environment = ambiente;