import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  marketplace = true;
  customerLogin : string = "";
  showAlax = true;
  constructor(
    public route: Router
  ) { 
    if (!environment.marketplace) {
      this.showAlax = false;
    } 
  }

  ngOnInit(): void {
    this.customerLogin = environment.customerUrl.login;
  }
  goToFaqs(){
    
      this.route.navigate(['/home/preguntas_frecuentes']);
    
    
  }
  goToContact(){
    this.route.navigate(['/contacto']);
  }

  goToCustomer(){
   // if (environment.production) {
     // window.location.href = this.customerLogin;
    //}else{
      this.route.navigate(['/clientes/login']);
    //}
    
  }


}
