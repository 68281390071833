<section class="how-works py-md-4 pt-3">
    <div class="container">

        <h4 class="mb-md-4 mb-3 text-center">{{'bucket.how_works' | translate}}</h4>
        <div class="d-md-flex align-items-center justify-content-md-around text-center">
            <div class="col-md-4 text-center">
                <div class="bucket">
                    <div class="image">
                        <img src="assets/images/how/ic-search.png" class="img-fluid" >
                    </div>
                    <h5 class="my-3">{{'bucket.step_1' | translate}}</h5>
                </div>
            </div>
            <div class="col-md-4 text-center mt-md-0 mt-4">
                <div class="bucket">
                    <div class="image">
                        <img src="assets/images/how/ic-discount.png" class="img-fluid">
                    </div>
                    <h5 class="my-3">{{'bucket.step_2' | translate}}</h5>
                </div>
            </div>
            <div class="col-md-4 text-center mt-md-0 mt-4">
                <div class="bucket">
                    <div class="image">
                        <img src="assets/images/how/ic-dni.png" class="img-fluid">
                    </div>
                    <h5 class="my-3">{{'bucket.step_3' | translate}}</h5>
                </div>
            </div>
        </div>
    </div>
    </section>