
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PublicService } from './public/public.service';
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token: string = localStorage.getItem('token');
    const isClient: String = localStorage.getItem('isClient')
    let request = req;
    
    if (isClient === "isClient" && req.url.indexOf('/api/') == -1 ) {
      request = req.clone({
        setHeaders: {
          "X-Customer-Token" : `${token}`
        }
      });
    } else {
      request = req.clone({
        setHeaders: {
          "X-Auth-Token" : `${token}`
        }
      });

    }
    return next.handle(request).pipe( tap(() => {},
    (err: any) => {
    if (err instanceof HttpErrorResponse) {
      if (err.status !== 401) {
       return;
      }
    }
  }))
  }


}
