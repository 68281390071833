<div class="filter mx-auto h-100">
    <form class="p-2" [formGroup]="filterForm" #f="ngForm" > 
        <p class="text-center">{{'filters.search_preference' | translate}}</p> 
        <select  formControlName="categories" name="categories">
            <option value="" selected>{{'filters.all_categories' | translate}}</option>
            <option value="eshop">E-Shop</option>
            <option *ngFor="let c of categories" [value]="c.seotag">{{c.nombre}}</option>
        </select>
        <select formControlName="types" name="types">
            <option value="" selected>{{'filters.all_discounts' | translate}}</option>
            <option *ngFor="let t of types" [value]="t.seotag">{{t.nombre}}</option>
        </select>
 
        <select formControlName="district" name="district">
            <option value="" selected>{{'filters.all_neighbourhoods' | translate}}</option>
            <option *ngFor="let d of district" [value]="d.seotag">{{d.nombre}}</option>
        </select>

        <input formControlName="text" [placeholder]="'filters.free_search' | translate" matInput>
        <div class="text-center">
            <button class="w-50 my-3 mx-auto" mat-raised-button color="basic" [mat-dialog-close]="filterForm.value">{{'filters.search' | translate}}</button>
        </div>
    </form>

</div>