<section class="newsletter py-4" *ngIf="showAlax">
    <div class="container text-center">
        <div class="col-md-8 mx-auto">
            <h3>{{'newsletter.title' | translate}}</h3>
            <div class="rectangule d-flex flex-column">

                <form [formGroup]="newsletterForm" #f="ngForm" 
                    (ngSubmit)="f.form.valid && subscribeNewsletter()">
                    <input matInput [placeholder]="'newsletter.email' | translate" formControlName="email" type="text"
                    pattern="[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,6}$" minlength="5" maxlength="255" required>
                    <div class="d-flex mt-3">

                        <button class="w-25" mat-raised-button color="link" >{{'common.send' | translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>