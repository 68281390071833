<mat-toolbar *ngIf="showAlax">
    <div class="container">
        <div class="d-flex justify-content-between">
            <span class="material-icons d-md-none mt-1 menu" (click)="openMenu()">menu</span>
            <div class="logo pointer" routerLink="/home"><img src="assets/images/logo.png" alt=""></div>
            <div class="links d-flex align-items-center">
                <span class="pointer d-md-block d-none" routerLink="/home" routerLinkActive="active">{{'menu.home' | translate}}</span>
                <span class="mx-3 pointer d-md-block d-none" routerLink="/cupones-descuentos" routerLinkActive="active">{{'menu.coupons' | translate}}</span>
                <span class="pointer d-md-block d-none" routerLink="/locales" routerLinkActive="active">{{'menu.local' | translate}}</span>
                <ng-container *ngIf="logged(); else unlogged">
                    <button  mat-stroked-button color="primary" class="align-items-center user-data py-1 mx-3 d-md-flex d-none"[matMenuTriggerFor]="menu">
                        <i class="fas fa-user"></i>
                        <div class="user-identification d-md-flex d-none flex-column align-items-start  ml-md-2 ps-1 pe-2">
                            <span class="name text-truncate">{{'common.hi' | translate}} {{getUsername()}}</span>
                        </div>
                        <i class="fas fa-chevron-down"></i>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item routerLink="/perfil/mi-perfil">{{'menu.my_account' | translate}}</button>
                        <button mat-menu-item (click)="logout()">{{'menu.logout' | translate}}</button>
                    </mat-menu>
                </ng-container>
                <ng-template #unlogged>
                    <span class="mx-3 pointer d-md-block d-none" routerLink="/usuarios_web/login"><button mat-stroked-button color="primary"
                            routerLinkActive="active">{{'menu.my_account' | translate}}</button></span>
                </ng-template>
                <span class="d-md-block d-none">
                    <button mat-raised-button color="accent" routerLink="/contacto">{{'menu.service' | translate}}</button></span>
                <span class="d-flex ms-3" (click)="openFilter()">
                    <mat-icon class="pointer" style="color: #fbad18;">search</mat-icon>
                </span>
            </div>
        </div>
    </div>
</mat-toolbar>