import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class LangService {
  private lang = new Subject<string>();
  lang$ = this.lang.asObservable();
  browserLang = this.translate.getBrowserLang();
  selectedLanguage: string;
  selectLanguages = [{ value: 'es', label: 'Español' }, { value: 'uy', label: 'Español' }, { value: 'es', label: 'Español' }];
  availableLanguage = [];
  globalLang: string;

  constructor(private translate: TranslateService) {
    this.availableLanguages();
    this.translate.setDefaultLang('es');
    var lng = environment.lang;
    

    this.globalLang = lng
      ? lng
      : this.availableLanguage.includes(this.browserLang)
      ? lng
      : 'es';

    translate.use(this.globalLang);
  }
  availableLanguages() {
    this.selectLanguages.forEach((x) => {
      this.availableLanguage.push(x.value);
    });
  }
}
