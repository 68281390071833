import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.marketplace) {
  document.getElementById('indexTitle').textContent = "Cuponeala";
  document.getElementById('favicon').setAttribute("href", "assets/images/favicon.ico");
} else {
  document.getElementById('indexTitle').textContent = "Alax";
  document.getElementById('favicon').setAttribute("href", "assets/images/alax-favicon.png");
}

function bootstrap() {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
};


if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}

