import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PublicService } from '../../public.service';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterDialog implements OnInit {
  filterForm: FormGroup;
  district: any
  categories: any
  types: any;
  constructor(
    private publicService: PublicService,
    private fb: FormBuilder) { }
    ngOnInit(): void {
      this.filterForm = this.fb.group({
      categories: new FormControl(''),
      types: new FormControl(''),
      district: new FormControl(''),
      text: new FormControl(''),
    });
      this.getNeighbourhoods()
      this.getDiscounts()
      this.getCategories()
    }
  
    getDiscounts() {
      this.publicService.getDiscounts().subscribe((x) =>
        this.types = x.types
      )
    }
    getNeighbourhoods() {
      this.publicService.getNeighbourhoods().subscribe((x) =>
        this.district = x.neighbourhoods
      )
    }
    
  getCategories() {
    this.publicService.getCategories().subscribe((x) =>
      this.categories = x.categories
      )
  }
}

