<!--- FOOTER CUPO -->
<footer class="footerCupo" *ngIf="showCupo">
    <div class="container footer-container">
        <div class="row">
            <div class="col-md-4">
                <div class="logo-footer mb-md-4 mb-3">
                    <img src="assets/images/logo.png" alt="" *ngIf="!clientsLogin && !logged()">
                    <img src="assets/images/ALAX_identificador-02.png" alt="" *ngIf="clientsLogin || logged()">
                </div>
                <div class="contact d-flex flex-column mail" *ngIf="!clientsLogin && !logged()">
                    <div class="d-flex align-items-center">

                        <span class="material-icons me-2">mail</span> {{'footer.write' | translate}} 
                    </div>
                    <a href="mailto:contacto@cuponeala.com"> {{ environment.commercialEmail }}</a>
                </div>
                <div class="contact d-flex flex-column mail" *ngIf="clientsLogin || logged()">
                    <div class="d-flex align-items-center">

                        <span class="material-icons me-2">mail</span> {{'footer.write' | translate}} 
                    </div>
                    <a href="mailto:contacto@cuponeala.com"> contacto@alax.tech </a>
                </div>
            </div>
            <div class="col-md-6" *ngIf="!clientsLogin && !logged()">
                <div class="title mb-3 mt-md-0 mt-4">{{'footer.categories' | translate}} </div>
                <div class="row">
                    <div class="col-6">
                        <div class="links d-flex flex-column">
                            <span (click)="goToCategorie('moda')">Moda</span>
                            <span (click)="goToCategorie('deco')">Deco</span>
                            <span (click)="goToCategorie('beauty')">Beauty</span>
                            <span (click)="goToCategorie('resto')">Resto</span>
                            <span (click)="goToCategorie('mascotas')">Mascotas</span>
                            <span (click)="goToCategorie('ninos')">Niños</span>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="links d-flex flex-column">
                            <span (click)="goToCategorie('hoteles')">Hoteles</span>
                            <span (click)="goToCategorie('cursos')">Cursos</span>
                            <span (click)="goToCategorie('empresarial')">Empresarial</span>
                            <span (click)="goToCategorie('experiencias')">Experiencias</span>
                            <span (click)="goToCategorie('alimentos')">Alimentos</span>
                            <span (click)="goToCategorie('servicios')">Servicios</span>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="row mt-md-4 mt-3">
            <div class="col-md-4" *ngIf="!clientsLogin && !logged()">
                © 2021 Copyright Cuponeala
                <a href="http://qr.afip.gob.ar/?qr=Qb1fKV19AGcPMJZ1qx7r4A,," target="_F960AFIPInfo"
                    class="ms-2"><img src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" alt="AAIP RNBD"
                        style="width:auto;height:57px;border:0;"></a>
            </div>
            <div class="col-md-4" *ngIf="clientsLogin || logged()">
                © 2021 Copyright Alax
                <a href="http://qr.afip.gob.ar/?qr=Qb1fKV19AGcPMJZ1qx7r4A,," target="_F960AFIPInfo"
                    class="ms-2"><img src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" alt="AAIP RNBD"
                        style="width:auto;height:57px;border:0;"></a>
            </div>
            <div class="col-md-4 text-md-center mt-md-0 mt-3">
                <span class="d-flex align-items-center justify-content-md-center">
            <span class="material-icons">phone</span>  <a class="ms-2" href="tel:{{environment.commercial.number}}"> {{environment.commercial.number}}</a> </span>{{ environment.commercial.schedule}}
                
                
            </div> <ul class="col-md-4 mt-md-0 mt-3 d-flex justify-content-md-end">
                
                <li><a class="fa fa-facebook" href="https://www.facebook.com/cuponeala/" target="_blank"></a></li>
                <li><a class="fa  fa-twitter" href="https://twitter.com/Cuponealaok" target="_blank"></a></li>
                <li><a class="fa fa-instagram" href="https://www.instagram.com/cuponeala/" target="_blank"></a></li>
                <li><a class="fa fa-linkedin-in" href="https://www.linkedin.com/company/cuponeala" target="_blank"></a></li>
                <li><a class="fa fa-youtube" href="https://www.youtube.com/channel/UCsvEHDVVULipo_zk9QSpTIA/featured" target="_blank"></a></li>
              </ul>
        </div>
    </div>
    <div class="container footer-powered-by" *ngIf="!clientsLogin && !logged()">
        <h5>Powered by Alax</h5>
    </div>
    <div class="container footer-powered-by" *ngIf="clientsLogin || logged()">
        <h5>Powered by Alax</h5>
    </div>
</footer> 


<!--- FOOTER ALAX -->
<footer class="row footer footerAlax" *ngIf="showAlax">
    <div class="ancho-maximo">
        <div class="col-sm-12 col-md-12">
            <img src="assets/services/images/alax-logo.png" class="logo" />
        </div>
        <div class="col-sm-6 col-md-6">
            <div class="item">
                <img src="assets/services/images/ico-email.png" class="ico-email" />
                <div class="description">
                    <span>Escribe tu consulta a:</span><br />
                    <a href="mailto:contacto@alax.tech" style="text-decoration:none">contacto@alax.tech</a>
                </div>
            </div>
            <div class="item">
                <a href="http://qr.afip.gob.ar/?qr=Qb1fKV19AGcPMJZ1qx7r4A,," target="_F960AFIPInfo" class="ms-2"
                    style="margin-left: 0px !important;">
                    <img src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" class="ico-copyright" />
                </a>
                <div class="description">
                    <span>&copy;2023 Copyright<br />
                    Alax</span>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-6">
            <div class="item">
                <div class="description padding-left-none">
                    <a href="https://data.alax.tech/files/alax-terminos-y-condiciones.pdf">Términos y Condiciones</a><br />
                    <a href="https://data.alax.tech/files/alax-privacidad.pdf">Políticas de Privacidad</a>
                </div>
            </div>
            <div class="item">
                <img src="assets/services/images/ico-redes-1.png" class="ico-redes" />
                <div class="description">
                    <span>Lunes a Viernes 9 a 13hs</span><br />
                    <a href="https://api.whatsapp.com/send?phone=541165443366" style="text-decoration:none">+54 11 6544-3366</a>
                </div>
                <div class="redes">
                    <a href="https://www.youtube.com/@Alax.tech23/featured" target="_blank"><img src="assets/services/images/ico-redes-2.png" /></a>
                    <a href="https://www.linkedin.com/company/alaxtech/" target="_blank"><img src="assets/services/images/ico-redes-3.png"/></a>
                    <a href="https://www.instagram.com/alax.tech/" target="_blank"><img src="assets/services/images/ico-redes-4.png" /></a>
                </div>
            </div>
        </div>
    </div>
</footer>