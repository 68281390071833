<mat-sidenav-container class="example-container">
  <mat-sidenav id="print" #sidenav  [mode]="mobileQuery.matches ? 'over' : 'side'"
  [fixedInViewport]="mobileQuery.matches">
  <div class="logo pointer" routerLink="/home" id="print" ><img src="assets/images/cuponeala-logo-sidenav.png" alt=""></div>
  <div class="w-100 my-3">
  <div class="user px-4 " *ngIf="logged()">
    <div>
      <img src="assets/images/ico-usuario-logueado.png" class="mCS_img_loaded">
      </div>
      <span>{{'common.hi' | translate}} {{getUsername()}}</span>
    </div>
    <ul>
        <li><span routerLink="/"><i class="fas fa-home"></i>{{'menu.home' | translate}}</span></li>
        <li><span routerLink="/cupones-descuentos"><i class="fas fa-tags"></i>{{'menu.coupons' | translate}}</span></li>
        <li><span routerLink="/locales"><i class="fas fa-store"></i> {{'menu.local' | translate}}</span></li>    
        <li><span routerLink="/perfil/mis-descuentos/vigentes" *ngIf="logged()"><i class="fas fa-user"></i> {{'menu.my_account' | translate}}</span></li>
        <li><span routerLink="/usuarios_web/login" *ngIf="!logged()"><i class="fas fa-user"></i> {{'menu.user_access' | translate}}</span></li>
        <li><span routerLink="/usuarios_web/registro" *ngIf="!logged()"><i class="fas fa-user-edit"></i> {{'menu.signup' | translate}}</span></li>            
        <li><span routerLink="/contacto"><i class="fas fa-envelope"></i> {{'menu.contact' | translate}}</span></li>
        <li><span routerLink="/preguntas_frecuentes"><i class="fas fa-question"></i> {{'menu.faq' | translate}}</span></li>
        <li><span (click)="goToCustomer()"><i class="fas fa-store"></i> {{'menu.local_access' | translate}}</span></li> 
        <li><span routerLink="/servicios"><i class="fas fa-ticket-alt"></i> {{'menu.service' | translate}}</span></li>
        <li><span (click)="logout()" *ngIf="logged()"><i class="fas fa-sign-out-alt"></i> {{'menu.logout' | translate}}</span> </li>
    </ul>
  </div>
</mat-sidenav> 
  <mat-sidenav-content>

    <app-header id="header" class="d-md-block d-none" *ngIf="!hasMatches('canales','landing', 'servicios','home/locales', 'clientes/adherir', 'validador', 'ventas', 'acciones', 'descuentos/reporte_locales', 'novedades', 'contactos', 'clientes', 'users', 'definir-clave', 'primer-pago', 'descargar-app')"></app-header>
    <app-nav id="nav" (menu)="getClick($event)" *ngIf="!hasMatches('canales','landing', 'servicios',  'home/locales', 'clientes/adherir', 'validador', 'ventas', 'acciones', 'descuentos/reporte_locales', 'novedades', 'contactos', 'clientes', 'users', 'definir-clave', 'primer-pago', 'descargar-app')"></app-nav>
    <router-outlet></router-outlet>
    <app-newsletter id="newsletter" *ngIf="!hasMatches('canales','landing', 'servicios', 'login', 'home/locales', 'clientes/adherir', 'validador', 'ventas', 'acciones', 'descuentos/reporte_locales', 'novedades', 'contactos', 'clientes', 'users', 'definir-clave', 'primer-pago', 'descargar-app')"></app-newsletter>
    <app-footer id="footer" *ngIf="!hasMatches('imprimir', '/descargar-app/daniel')"></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>


<a *ngIf= "whatsapp && !hasMatches('landing') && !showAlax"  href="https://api.whatsapp.com/send?phone={{whatsapp}}&text=Hola%21%20Cuponeala" class="float" target="_blank">
  <i class="fa fa-whatsapp my-float"></i>
</a>


  <!-- <div *ngIf="loadingService.isLoading$ | async" id="loading">
    <img class="loader" src="assets/images/ala_anim_1.gif"  width="150px">
  </div> -->