<div class="cards pb-2" (click)="openDetailCoupon(isClient)">
  <div class="name d-flex align-items-center p-2">
    <div class="avatar">
      <img
        [src]="coupons.logo"
        alt="Logo {{ coupons.local_nombre }}"
        title="Local {{ coupons.local_nombre }}"
      />
    </div>
    <h2 class="ms-3 text-truncate">{{ coupons.local_nombre }}</h2>
    <i class="fas fa-share-alt ms-auto" (click)="openWhats()" *ngIf="shareLink" [style.color]="primaryColor"></i>
  </div>
  <div class="d-flex images">
    <div class="col-6" *ngIf="coupons.cupon_imagen != null">
      <div class="image">
        <img
          [src]="coupons.cupon_imagen"
          alt="Imagen {{ coupons.local_nombre }}"
          title="{{ coupons.local_nombre }}"
        />
      </div>
    </div>
    <div
      class="percentage d-flex align-items-center justify-content-center"
      [ngClass]="coupons.cupon_imagen == null ? 'col-12 image-full' : 'col-6'"
      [style.backgroundColor]="primaryColor"
    >
      <span *ngIf="!coupons.encuesta_id">{{ coupons.nombre }}</span>
      <span *ngIf="coupons.encuesta_id" style="font-size: 36px;">Encuesta</span>
    </div>
  </div>
  <div class="rotulo-programa-de-puntos p-2" *ngIf="coupons.cupon_tipo === 'canje' && coupons.puntos_valor">
    {{ coupons.puntos_valor }} puntos
  </div>
  <div class="rotulo-programa-de-puntos p-2" *ngIf="coupons.cupon_tipo === 'canje' && !coupons.puntos_valor">
    Programa de puntos
  </div>
  <div class="rotulo-programa-de-puntos p-2" *ngIf="coupons.cupon_tipo === 'cumpleanios'">
    Cumpleaños
  </div>
  <div class="rotulo-programa-de-puntos p-2" *ngIf="coupons.cupon_tipo === 'aniversario'">
    Aniversario
  </div>
  <div class="description p-2" *ngIf="!coupons.encuesta_id">{{ coupons.descripcion }}</div>
  <div
    class="bg p-1 d-flex align-items-center justify-content-between mx-2 mb-2" [style.backgroundColor]="softColor"
  >
    <span class="d-flex days">
      <span [ngClass]="{ selected: coupons.dia_lu }" [style.backgroundColor]="coupons.dia_lu ? primaryColor : ''">L</span>
      <span [ngClass]="{ selected: coupons.dia_ma }" [style.backgroundColor]="coupons.dia_ma ? primaryColor : ''">M</span>
      <span [ngClass]="{ selected: coupons.dia_mi }" [style.backgroundColor]="coupons.dia_mi ? primaryColor : ''">M</span>
      <span [ngClass]="{ selected: coupons.dia_ju }" [style.backgroundColor]="coupons.dia_ju ? primaryColor : ''">J</span>
      <span [ngClass]="{ selected: coupons.dia_vi }" [style.backgroundColor]="coupons.dia_vi ? primaryColor : ''">V</span>
      <span [ngClass]="{ selected: coupons.dia_sa }" [style.backgroundColor]="coupons.dia_sa ? primaryColor : ''">S</span>
      <span [ngClass]="{ selected: coupons.dia_do }" [style.backgroundColor]="coupons.dia_do ? primaryColor : ''">D</span>
    </span>
    <span class="date" [style.color]="primaryColor"
      >{{ "coupon.until" | translate }}
      {{ coupons.fecha_hasta | date: "dd/MM" }}
    </span>
  </div>
  <div class="row mx-2 mb-2">
    <div class="col-6 pe-3 mb-md-0 ps-0 mb-2">
      <div class="bg p-1 location" [style.backgroundColor]="softColor">
        <ng-container *ngIf="coupons.tipo == 'local'; else eshop">
          <ng-container *ngIf="activated; else neighbour">
          <i class="fas fa-map-marker-alt me-1" *ngIf="secondaryColor; else defaultMapIcon" style="color:#828282;"></i>
          <ng-template #defaultMapIcon>
            <i class="fas fa-map-marker-alt me-1"></i>
          </ng-template>
          {{ coupons.distancia ? (coupons.distancia | fixed: "2") + "km" : "" }}
          {{ coupons.barrio }}
          </ng-container>
          <ng-template #neighbour>
            {{ coupons.barrio }}
          </ng-template>
        </ng-container>
        <ng-template #eshop>
          {{ coupons.categoria_nombre }}
        </ng-template>
      </div>
    </div>
    <div class="col-6 px-0" *ngIf="withButtons && !coupons.encuesta_id">
      <button class="landingButton" mat-raised-button *ngIf="secondaryColor; else defaultButton" [style.backgroundColor]="secondaryColor">
        {{ "coupon.activate_coupon" | translate }}
      </button>
      <ng-template #defaultButton>
        <button mat-raised-button color="basic">
          {{ "coupon.activate_coupon" | translate }}
        </button>
      </ng-template>
    </div>
    <div class="col-6 px-0" *ngIf="withButtons && coupons.encuesta_id">
      <button class="landingButton" mat-raised-button *ngIf="secondaryColor; else defaultButtonEncuesta" [style.backgroundColor]="secondaryColor">
        Completar Encuesta
      </button>
      <ng-template #defaultButtonEncuesta>
        <button mat-raised-button color="basic">
          Completar Encuesta
        </button>
      </ng-template>
    </div>
  </div>
  <div
    class="whatsapp p-1 d-flex align-items-center mx-2 mb-2 pointer"
    (click)="sendWhatsapp()"
    *ngIf="withButtons"
    [ngClass]="{ chat: coupons.whatsapp }"
    [style.color]="primaryColor"
    [style.backgroundColor]="coupons.whatsapp != null ? softColor : ''"
  >
    <ng-container *ngIf="coupons.whatsapp != null">
      <i class="fab fa-whatsapp me-2"></i>{{ "coupon.chat_local" | translate }}
    </ng-container>
  </div>
</div>
