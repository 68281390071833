import { Injectable } from '@angular/core';


@Injectable()
export class Session {
 

  constructor() {}


  hp(permission){
      return localStorage.getItem("permissions").split(",").includes(permission);
  }

  isClient(){
      return localStorage.getItem('profile')==='Cliente';
  }

}