import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'districtFilter',
  pure: true,
})
export class DistrictFilter implements PipeTransform {
  transform(items: any[], filter: any): any { 
    return items?.filter(x => { return x.provincia_id == filter})
  }
}
