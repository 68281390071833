import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiBridgeService } from '../api-bridge.service';
// import amplitude from 'amplitude-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private apiBridge: ApiBridgeService,
    private router: Router
  ) { }
  signIn(data: any): Observable<any> {
    return this.apiBridge.post(`consumer`,`login`, data)
  }
  signUp(data: any): Observable<any> {
    return this.apiBridge.post(`consumer`,`signup`, data)
  } 
  resetPassword(data: any): Observable<any> {
    return this.apiBridge.post(`consumer`,`reset_password`, data)
  }
  updatePassword(data: any): Observable<any> {
    return this.apiBridge.post(`consumer`,`update_password`, data)
  }
  logout(){ 
 //   amplitude.logEvent("Logging out");
    localStorage.clear();
   // amplitude.getInstance().setUserId(null);
    this.router.navigateByUrl('/');
   }
   deleteAccountSendEmail(data: any): Observable<any> {
    return this.apiBridge.post(`consumer`,`delete_account_send_email`, data)
   }
   deleteAccount(data: any): Observable<any> {
    return this.apiBridge.post(`consumer`,`delete_account`, data)
   }
}


