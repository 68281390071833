import { Component, Input, OnInit } from '@angular/core';
import { environment as env } from '../../../../environments/environment';
import amplitude from 'amplitude-js';
import { MatDialog } from '@angular/material/dialog';
import { CouponShareDialog } from '../coupon/coupon-share/coupon-share.component';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  @Input() zoom: number;
  lat: number = env.zone.lat;
  lng: number = env.zone.lng;
  @Input() markers: any;
  @Input() width: number;
  @Input() height: number;
  @Input() primaryColor: any;
  @Input() secondaryColor: any;
  @Input() softColor: any;

  iconImage;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.getUserLocation();
  }

  getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
      });
    }
  }

  sendWhatsapp(whatsapp) {
    window.open(`https://api.whatsapp.com/send?phone=${whatsapp}`, '_blank');
  }

  openDetailCoupon(coupon): void {
    amplitude.getInstance().logEvent('Viewing Coupon', {
      coupon: coupon.id,
      hop: coupon.local_id,
      name: coupon.local_nombre,
      type: coupon.nombre,
      category: coupon.categoria_nombre,
    });
    this.dialog.open(CouponShareDialog, {
      panelClass: ['dialog', 'common'],
      width: '500px',
      data: {
        coupons: coupon,
        primaryColor: this.primaryColor,
        secondaryColor: this.secondaryColor,
        softColor: this.softColor
      },
    });
  }
}
