<div class="pb-2">
  <i class="material-icons float-end" [mat-dialog-close]="">close</i>
  <div class="name d-flex align-items-center p-2">
    <div class="avatar">
      <img
        [src]="data.coupons.logo"
        alt="Logo {{ data.coupons.local_nombre }}"
        title="Local {{ data.coupons.local_nombre }}"
      />
    </div>
    <h2 class="ms-3 text-truncate">{{ data.coupons.local_nombre }}</h2>
    <div class="share">
      <div class="share-box" (click)="share('F')" [style.backgroundColor]="data.primaryColor">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 32 32"
          version="1.1"
          role="img"
          aria-labelledby="at-svg-facebook-1"
          class="at-icon at-icon-facebook"
          style="fill: rgb(255, 255, 255); width: 20px; height: 20px"
        >
          <title id="at-svg-facebook-1">Facebook</title>
          <g>
            <path
              d="M22 5.16c-.406-.054-1.806-.16-3.43-.16-3.4 0-5.733 1.825-5.733 5.17v2.882H9v3.913h3.837V27h4.604V16.965h3.823l.587-3.913h-4.41v-2.5c0-1.123.347-1.903 2.198-1.903H22V5.16z"
              fill-rule="evenodd"
            ></path>
          </g>
        </svg>
      </div>
      <div class="share-box" (click)="share('T')" [style.backgroundColor]="data.primaryColor">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 32 32"
          version="1.1"
          role="img"
          aria-labelledby="at-svg-twitter-2"
          class="at-icon at-icon-twitter"
          style="fill: rgb(255, 255, 255); width: 20px; height: 20px"
        >
          <title id="at-svg-twitter-2">Twitter</title>
          <g>
            <path
              d="M27.996 10.116c-.81.36-1.68.602-2.592.71a4.526 4.526 0 0 0 1.984-2.496 9.037 9.037 0 0 1-2.866 1.095 4.513 4.513 0 0 0-7.69 4.116 12.81 12.81 0 0 1-9.3-4.715 4.49 4.49 0 0 0-.612 2.27 4.51 4.51 0 0 0 2.008 3.755 4.495 4.495 0 0 1-2.044-.564v.057a4.515 4.515 0 0 0 3.62 4.425 4.52 4.52 0 0 1-2.04.077 4.517 4.517 0 0 0 4.217 3.134 9.055 9.055 0 0 1-5.604 1.93A9.18 9.18 0 0 1 6 23.85a12.773 12.773 0 0 0 6.918 2.027c8.3 0 12.84-6.876 12.84-12.84 0-.195-.005-.39-.014-.583a9.172 9.172 0 0 0 2.252-2.336"
              fill-rule="evenodd"
            ></path>
          </g>
        </svg>
      </div>
      <div class="share-box" (click)="share('W')" [style.backgroundColor]="data.primaryColor">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 32 32"
          version="1.1"
          role="img"
          aria-labelledby="at-svg-whatsapp-3"
          class="at-icon at-icon-whatsapp"
          style="fill: rgb(255, 255, 255); width: 20px; height: 20px"
        >
          <title id="at-svg-whatsapp-3">WhatsApp</title>
          <g>
            <path
              d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
              fill-rule="evenodd"
            ></path>
          </g>
        </svg>
      </div>
    </div>
  </div>
  <div class="d-flex">
    <div class="col-6" *ngIf="data.coupons.cupon_imagen != null">
      <div class="image">
        <img
          [src]="data.coupons.cupon_imagen"
          alt="Imagen {{ data.coupons.local_nombre }}"
          title="{{ data.coupons.local_nombre }}"
        />
      </div>
    </div>
    <div *ngIf="!data.coupons.encuesta_id || encuestaSent"
      class="percentage d-flex align-items-center justify-content-center"
      [ngClass]="data.coupons.cupon_imagen == null ? 'col-12' : 'col-6'"
      [style.backgroundColor]="data.primaryColor ? data.primaryColor : '#009cd7'"
    >
      <span>{{ data.coupons.nombre }}</span>
    </div>
    <div *ngIf="data.coupons.encuesta_id && !encuestaSent"
      class="percentage percentage-encuesta d-flex align-items-center justify-content-center"
      [ngClass]="data.coupons.cupon_imagen == null ? 'col-12' : 'col-6'"
      [style.backgroundColor]="data.primaryColor ? data.primaryColor : '#009cd7'"
    >
      <span>{{ encuesta.titulo }}</span>
    </div>
  </div>
  <div class="description p-2" *ngIf="!data.coupons.encuesta_id || encuestaSent">{{ data.coupons.descripcion }}</div>
  <div class="description p-2" *ngIf="data.coupons.encuesta_id && !encuestaSent">{{ encuesta.descripcion }}</div>
  <div
    class="bg p-1 d-flex align-items-center justify-content-between mx-2 mb-2" [style.backgroundColor]="data.softColor"
  >
    <span class="d-flex days">
      <span [ngClass]="{ selected: data.coupons.dia_lu }" [style.backgroundColor]="data.coupons.dia_lu ? data.primaryColor : ''">L</span>
      <span [ngClass]="{ selected: data.coupons.dia_ma }" [style.backgroundColor]="data.coupons.dia_ma ? data.primaryColor : ''">M</span>
      <span [ngClass]="{ selected: data.coupons.dia_mi }" [style.backgroundColor]="data.coupons.dia_mi ? data.primaryColor : ''">M</span>
      <span [ngClass]="{ selected: data.coupons.dia_ju }" [style.backgroundColor]="data.coupons.dia_ju ? data.primaryColor : ''">J</span>
      <span [ngClass]="{ selected: data.coupons.dia_vi }" [style.backgroundColor]="data.coupons.dia_vi ? data.primaryColor : ''">V</span>
      <span [ngClass]="{ selected: data.coupons.dia_sa }" [style.backgroundColor]="data.coupons.dia_sa ? data.primaryColor : ''">S</span>
      <span [ngClass]="{ selected: data.coupons.dia_do }" [style.backgroundColor]="data.coupons.dia_do ? data.primaryColor : ''">D</span>
    </span>
    <span class="date" *ngIf="data.coupons.hora_desde && data.coupons.hora_hasta"> entre {{data.coupons.hora_desde | date:"H:mm"}}hs-{{data.coupons.hora_hasta | date:"H:mm"}}hs </span>
    <span class="date"  [style.color]="data.primaryColor"
      >{{ "coupon.until" | translate }}
      {{ data.coupons.fecha_hasta | date: "dd/MM" }} 
    </span>
  </div>
  <div class="bg mx-2 mb-2 p-1 location break-all"  [style.backgroundColor]="data.softColor">
    <ng-container *ngIf="data.coupons.cupon_tipo != 'eshop'; else eshopi">
      <span class="material-icons"> location_on </span>
      {{
        data.coupons.distancia
          ? (data.coupons.distancia | fixed: "2") + "km"
          : ""
      }}
      {{ data.coupons.direccion }}, {{ data.coupons.barrio }}
    </ng-container>
    <ng-template #eshopi>
      {{ data.coupons.categoria_nombre }}
    </ng-template>
  </div>
  <div class="col-12">
    <div
      *ngIf="data.coupons.whatsapp != null"
      class="whatsapp pointer p-1 d-flex align-items-center mx-2 mb-2"
      [ngClass]="{ chat: data.coupons.whatsapp }"
      (click)="sendWhatsapp(data.coupons.whatsapp)"
      [style.backgroundColor]="data.softColor" [style.color]="data.primaryColor"
    >
      <ng-container>
        <i class="fab fa-whatsapp me-2"></i>
        {{ "coupon.chat_local" | translate }}
      </ng-container>
    </div>
  </div>

  <p
    class="px-2 mb-0 more"
    style="overflow: hidden"
    [ngClass]="{ 'more-space': more}" >{{ data.coupons.legales }}
  </p>
 <div *ngIf="data.coupons.legales.length > 150" class="more px-2 d-flex align-items-right mb-2">
    <span (click)="vermas()">{{  more ? 'ver menos' : 'ver más'  }}</span>
  </div> 

  <!-- Vista logueado -->
  <ng-container *ngIf="logged(); else unlogged">
    <!-- Form activar cupón -->
    <div *ngIf="form; else success">
      <form
        [formGroup]="loggedCouponForm"
        #f="ngForm"
        (ngSubmit)="f.form?.valid && loggedActivateCoupon()"
      >
        <input type="text" formControlName="id" class="form-control" hidden />
        <input
          type="text"
          formControlName="origen"
          class="form-control"
          hidden
        />
        <input
          type="text"
          formControlName="local_id"
          class="form-control"
          hidden
        />
        <div class="bottom">
          <div class="col-md-4 col-8 mx-auto my-3">
            <button class="w-100" mat-raised-button color="basic">
              {{ "coupon.download_coupon" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>

    <!-- Vista cupón activado -->
    <ng-template #success>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 2px solid;
          position: relative;
          float: left;
          width: 100%;
          margin-top: 2px;
        "
        [style.border-color]="data.primaryColor ? data.primaryColor : '#009cd7'"
      >
        <div
          style="
            border-style: solid;
            border-width: 0 20px 20px 20px;
            width: 1px;
            height: 15px;
          "
          [style.border-color]="data.primaryColor ? transparentBorder : 'transparent transparent #009cd7 transparent'"
        ></div>
      </div>
      <div class="data padding-none">
        <div class="data-info padding-cupones">
          <div class="contenedor-texto-cupon height-auto text-center">
            <!-- Logo -->
            <div class="cont-imagen mt-4 mb-4" *ngIf="!isPartner">
              <img src="assets/images/logo.png" class="logo-descarga" />
            </div>

            <!-- Texto: "felicitaciones y válido hasta" -->
            <div *ngIf="data.coupons.nombre == 'SORTEO'; else normalSorteo" [style.color]="data.primaryColor">
              <h1>
                {{ "coupon.thanksFor" | translate }}
              </h1>
              <h2>
                {{ "coupon.followUs" | translate }}
              </h2>
              <h3>
                {{ "coupon.sortea" | translate }}
                {{ data.coupons.fecha_hasta | date: "dd/MM" }}
              </h3>
            </div>
            <ng-template #normalSorteo>
              <h1 [style.color]="data.primaryColor ? data.primaryColor : '#009cd7'">
                {{ "coupon.congratulation" | translate }}
              </h1>
              <h4 [style.color]="data.primaryColor ? data.primaryColor : '#009cd7'">
                {{ "coupon.use_coupon" | translate }}
              </h4>
              <h4 [style.color]="data.primaryColor ? data.primaryColor : '#009cd7'">
                {{ "coupon.valid_until" | translate }}
                {{ data.coupons.fecha_hasta | date: "dd/MM" }}
              </h4>
              <h4 [style.color]="data.primaryColor ? data.primaryColor : '#009cd7'" 
                *ngIf="data.coupons.hora_desde && data.coupons.hora_hasta">
                ENTRE {{data.coupons.hora_desde | date:"H:mm"}}HS y {{data.coupons.hora_hasta | date:"H:mm"}}HS
              </h4>
            </ng-template>

            <!-- QR via email -->
            <h3 *ngIf="data.coupons.qr_scanner">
              <span style="margin-top: 15px; font-size: 17px;" [style.color]="data.primaryColor ? data.primaryColor : '#009cd7'">
                Podra obtener el beneficio presentando en la tienda el QR enviado por mail.
              </span>
            </h3>

            <!-- Link, código, barcode, etc -->
            <h3 *ngIf="barcode;else noBarcode">
              <span style="margin-top: 15px; font-size: 17px;" [style.color]="data.primaryColor">
                Para realizar la compra con descuento utilizá el siguiente código: <br>
                <img src="{{barcode}}" alt="">
              </span>
            </h3>  

            <ng-template #noBarcode>
            <h3
              style="margin-top: 15px; font-size: 17px; overflow-wrap: break-word;word-wrap: break-word;" 
              [style.color]="data.primaryColor"
              *ngIf="(data.coupons.cupon_tipo == 'eshop' || descuento_codigo_eshop) &&
              data.coupons.nombre != 'SORTEO'; else normalCodigo"
            >
              {{ "coupon.add_discount" | translate }} <br />

              <div
                *ngIf="
                  data.coupons.codigo_eshop.includes('http');
                  else normalEshop
                "
              >
                <a *ngIf="!descuento_codigo_eshop"
                  [href]="data.coupons.codigo_eshop"
                  target="_blank"
                  style="color: #2f3068; font-weight: 600"
                  >{{ data.coupons.codigo_eshop }}</a
                >
                <span style="font-size: 20px; font-weight: 600" *ngIf="descuento_codigo_eshop">{{
                  descuento_codigo_eshop
                }}</span>
              </div>
              <ng-template #normalEshop>
                <a *ngIf="!data.coupons.url_eshop"
                  [href]="web()"
                  target="_blank"
                  style="color: #2f3068; font-weight: 600"
                  >{{ data.coupons.local_nombre }}</a
                >
                <a *ngIf="data.coupons.url_eshop"
                  [href]="web()"
                  target="_blank"
                  style="color: #2f3068; font-weight: 600"
                  >{{ data.coupons.url_eshop }}</a
                >
                {{ "coupon.use_code" | translate }}<br />
                <span style="font-size: 20px; font-weight: 600" *ngIf="!descuento_codigo_eshop">{{
                  data.coupons.codigo_eshop
                }}</span>
                <span style="font-size: 20px; font-weight: 600" *ngIf="descuento_codigo_eshop">{{
                  descuento_codigo_eshop
                }}</span>
              </ng-template>
            </h3>
            </ng-template>

            <ng-template *ngIf="data.coupons.nombre != 'SORTEO'" #normalCodigo>
              <h3 [style.color]="data.primaryColor">
                {{ "coupon.only_enable_locals" | translate }}
              </h3>
            </ng-template>

            <!-- Iconos social media -->
            <div class="share mt-3 justify-content-center">
              <div class="share-box" (click)="share('F')" [style.backgroundColor]="data.primaryColor">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 32 32"
                  version="1.1"
                  role="img"
                  aria-labelledby="at-svg-facebook-1"
                  class="at-icon at-icon-facebook"
                  style="fill: rgb(255, 255, 255); width: 20px; height: 20px"
                >
                  <title id="at-svg-facebook-1">Facebook</title>
                  <g>
                    <path
                      d="M22 5.16c-.406-.054-1.806-.16-3.43-.16-3.4 0-5.733 1.825-5.733 5.17v2.882H9v3.913h3.837V27h4.604V16.965h3.823l.587-3.913h-4.41v-2.5c0-1.123.347-1.903 2.198-1.903H22V5.16z"
                      fill-rule="evenodd"
                    ></path>
                  </g>
                </svg>
              </div>
              <div class="share-box" (click)="share('T')"  [style.backgroundColor]="data.primaryColor" [style.backgroundColor]="data.primaryColor">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 32 32"
                  version="1.1"
                  role="img"
                  aria-labelledby="at-svg-twitter-2"
                  class="at-icon at-icon-twitter"
                  style="fill: rgb(255, 255, 255); width: 20px; height: 20px"
                >
                  <title id="at-svg-twitter-2">Twitter</title>
                  <g>
                    <path
                      d="M27.996 10.116c-.81.36-1.68.602-2.592.71a4.526 4.526 0 0 0 1.984-2.496 9.037 9.037 0 0 1-2.866 1.095 4.513 4.513 0 0 0-7.69 4.116 12.81 12.81 0 0 1-9.3-4.715 4.49 4.49 0 0 0-.612 2.27 4.51 4.51 0 0 0 2.008 3.755 4.495 4.495 0 0 1-2.044-.564v.057a4.515 4.515 0 0 0 3.62 4.425 4.52 4.52 0 0 1-2.04.077 4.517 4.517 0 0 0 4.217 3.134 9.055 9.055 0 0 1-5.604 1.93A9.18 9.18 0 0 1 6 23.85a12.773 12.773 0 0 0 6.918 2.027c8.3 0 12.84-6.876 12.84-12.84 0-.195-.005-.39-.014-.583a9.172 9.172 0 0 0 2.252-2.336"
                      fill-rule="evenodd"
                    ></path>
                  </g>
                </svg>
              </div>
              <div class="share-box" (click)="share('W')" [style.backgroundColor]="data.primaryColor">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 32 32"
                  version="1.1"
                  role="img"
                  aria-labelledby="at-svg-whatsapp-3"
                  class="at-icon at-icon-whatsapp"
                  style="fill: rgb(255, 255, 255); width: 20px; height: 20px"
                >
                  <title id="at-svg-whatsapp-3">WhatsApp</title>
                  <g>
                    <path
                      d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                      fill-rule="evenodd"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>

          <!-- Texto: aclaraciones sobre el cupón -->
          <div class="separador"></div>
          <div style="text-align: center">
            <span class="borde-inferior" style="color: #313131; font-size: 14px"
              >{{ "coupon.print_coupon" | translate }}
              <span style="color: #313131; font-size: 14px" *ngIf="data.coupons.cupon_tipo != 'eshop'"> {{'coupon.how_to_use_coupon'| translate}} </span><br />
              {{ "coupon.if_print_coupon" | translate }}

              <a
                href="javascript:window.print()"
                class="btn-impresion"
                style="color: #313131 !important; font-weight: bold !important"
                >{{ "coupon.here" | translate }}</a
              ><br /><br />
              {{ "coupon.email_send" | translate }}<br /><br />
            </span>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <!-- Vista sin loguear -->
  <ng-template #unlogged>
    <!-- Form activar cupón -->
    <div *ngIf="form; else success">
      <form
        [formGroup]="unloggedCouponForm"
        #f="ngForm"
        (ngSubmit)="
          f.form.valid &&
            unloggedActivateCoupon(
              data.coupons.local_id,
              data.coupons.id,
              'web'
            )
        "
      >
        <div class="bg-form p-3" [style.backgroundColor]="data.primaryColor">

          <form [formGroup]="encuestaForm" (change)="onEncuestaChange()">
            <div class="encuesta-div" *ngIf="data.coupons.encuesta_id">
              <p class="pb-2">Completar la siguiente encuesta para obtener un beneficio sorpresa:</p>
              <div class="encuesta-fields-div">
                <div class="form-group mb-2" *ngFor="let p of encuesta.preguntas">
                  <p>{{p.texto}} <span *ngIf="p.obligatoria">(*)</span> </p>
                  <div *ngIf="p.tipo == 'radio'">
                    <div *ngFor="let o of p.opciones">
                        <input type="radio" formControlName="encuesta-pregunta-id-{{p.id}}" value="{{o}}"> {{o}}
                    </div>
                  </div>
                  <div *ngIf="p.tipo == 'multi'">
                    <select class="form-control" formControlName="encuesta-pregunta-id-{{p.id}}" 
                      [style.backgroundColor]="data.primaryColor ? data.primaryColor : '#009cd7'">
                      <option *ngFor="let o of p.opciones" [value]="o">
                        {{ o }}
                      </option>
                    </select>
                  </div>
                  <div *ngIf="p.tipo == 'texto'">
                    <input type="text" class="form-control" formControlName="encuesta-pregunta-id-{{p.id}}">
                  </div>
                </div>
              </div>
              <p style="text-align: left;">(*) Campos obligatorios</p>
              <br>  
            </div>
          </form>

          <p  *ngIf="!data.coupons.encuesta_id && data.coupons.nombre != 'SORTEO'">{{ "coupon.get_discount" | translate }}</p>
          <p  *ngIf="!data.coupons.encuesta_id && data.coupons.nombre == 'SORTEO'">Completando la siguiente información participas del sorteo</p>
          <p *ngIf="data.coupons.encuesta_id">Completa tus datos personales:</p>
          <div class="form-group mb-2">
            <input
              type="text"
              formControlName="nombre"
              [placeholder]="'coupon.name' | translate"
              class="form-control"
              minlength="1"
              maxlength="40"
              required
            />
          </div>
          <div class="form-group mb-2">
            <input
              type="text"
              formControlName="apellido"
              [placeholder]="'coupon.last_name' | translate"
              class="form-control"
              minlength="1"
              maxlength="40"
              required
            />
          </div>

          <div class="form-group mb-2">
            <input
              type="number"
              formControlName="dni"
              [placeholder]="'coupon.document' | translate"
              class="form-control"
              minlength="1"
              matInput
              maxlength="10"
              pattern="[0-9]{1,10}$"
              required
            />
          </div>
          <div class="form-group mb-2">
            <input
              formControlName="email"
              type="email"
              [placeholder]="'coupon.email' | translate"
              class="form-control"
              minlength="5"
              maxlength="255"
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              required
            />
          </div>
          <div class="form-group mb-2" *ngIf="data.coupons.pedir_whatsapp" style="display: flex;">
            <input
              formControlName="movil_codigo_area"
              type="number"
              class="form-control"
              placeholder="Cod. Área"
              required
              style="width: 29%; margin-right: 1%;"
            />
            <input
              formControlName="movil_numero"
              type="number"
              class="form-control ml-1"
              placeholder="Número de Whatsapp"
              required
              style="width: 69%;margin-left: 1%;"
            />
          </div>
          <mat-checkbox formControlName="mayoria_edad" class="my-2" required *ngIf="data.coupons.pedir_mayoria_edad">
            Soy mayor de 18 años
          </mat-checkbox>
          <br *ngIf="data.coupons.pedir_mayoria_edad">
          <mat-checkbox formControlName="acepto_terminos" class="my-2" required
            >{{ "coupon.accept" | translate }}
            <a
              href="https://data.alax.tech/files/alax-terminos-y-condiciones.pdf"
              style="color: #73a7da"
              target="_blank"
              >{{ "coupon.tyc" | translate }}</a
            ></mat-checkbox
          >
        </div>
        <div class="buttons col-md-4 mx-auto my-md-3 px-md-0 px-3" *ngIf="!data.coupons.encuesta_id">
          <button class="landingButton" mat-raised-button *ngIf="data.secondaryColor; else defaultButton" [style.backgroundColor]="data.secondaryColor">
            {{ "coupon.activate_coupon" | translate }}
          </button>
          <ng-template #defaultButton>
            <button class="w-100" [disabled]="!f.form.valid" mat-raised-button color="basic">
              {{ "coupon.activate_coupon" | translate }}
            </button>
          </ng-template>
        </div>
        <div class="buttons col-md-4 mx-auto my-md-3 px-md-0 px-3" *ngIf="data.coupons.encuesta_id">
          <button class="landingButton" [disabled]="!f.form.valid || !encuestaFormIsValid" mat-raised-button *ngIf="data.secondaryColor; else encuestaDefaultButton" [style.backgroundColor]="data.secondaryColor">
            Enviar Encuesta
          </button>
          <ng-template #encuestaDefaultButton>
            <button class="w-100" [disabled]="!f.form.valid || !encuestaFormIsValid" mat-raised-button color="basic" 
              *ngIf="data.coupons.encuesta_id">Enviar Encuesta</button>
          </ng-template>
        </div>
      </form>
    </div>

    <!-- Vista cupón activado -->
    <ng-template #success>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 2px solid;
          position: relative;
          float: left;
          width: 100%;
          margin-top: 2px;
        "
        [style.border-color]="data.primaryColor ? data.primaryColor : '#009cd7'"
      >
        <div
          style="
            border-style: solid;
            border-width: 0 20px 20px 20px;
            width: 1px;
            height: 15px;
          "
          [style.border-color]="data.primaryColor ? transparentBorder : 'transparent transparent #009cd7 transparent'"
        ></div>
      </div>
      <div class="data padding-none">
        <div class="data-info padding-cupones">
          <div class="contenedor-texto-cupon height-auto text-center">
            <!-- Logo -->
            <div class="cont-imagen mt-4 mb-4" *ngIf="!isPartner">
              <img src="assets/images/logo.png" class="logo-descarga" />
            </div>

            <!-- Texto: "felicitaciones y válido hasta" -->  
            <div *ngIf="data.coupons.nombre == 'SORTEO'; else normalSorteo" [style.color]="data.primaryColor">
              <h1>
                {{ "coupon.thanksFor" | translate }}
              </h1>
              <h2>
                {{ "coupon.followUs" | translate }}
              </h2>
              <h3>
                {{ "coupon.sortea" | translate }}
                {{ data.coupons.fecha_hasta | date: "dd/MM" }}
              </h3>
            </div>
            <ng-template #normalSorteo>
              <h1 [style.color]="data.primaryColor ? data.primaryColor : '#009cd7'"  *ngIf="!data.coupons.encuesta_id">
                {{'coupon.congratulation'| translate}}
              </h1>
              <h1 [style.color]="data.primaryColor ? data.primaryColor : '#009cd7'"  *ngIf="data.coupons.encuesta_id">
                ¡Gracias por tu respuesta!
              </h1>
              <h4 [style.color]="data.primaryColor ? data.primaryColor : '#009cd7'" *ngIf="data.coupons.cupon_tipo != 'diferido'">
                {{ "coupon.use_coupon" | translate }}
              </h4>
              <h4 [style.color]="data.primaryColor ? data.primaryColor : '#009cd7'" *ngIf="data.coupons.cupon_tipo == 'diferido'">
                Podrás utilizar tu cupón a partir del {{ data.coupons.fecha_desde | date: "dd/MM" }}
              </h4>
              <h4 [style.color]="data.primaryColor ? data.primaryColor : '#009cd7'">
                {{ "coupon.valid_until" | translate }}
                {{ data.coupons.fecha_hasta | date: "dd/MM" }}
              </h4>
              <h4 [style.color]="data.primaryColor ? data.primaryColor : '#009cd7'" 
                *ngIf="data.coupons.hora_desde && data.coupons.hora_hasta">
                ENTRE {{data.coupons.hora_desde | date:"H:mm"}}HS y {{data.coupons.hora_hasta | date:"H:mm"}}HS
              </h4>
            </ng-template>

            <!-- QR via email -->
            <h3 *ngIf="data.coupons.qr_scanner">
              <span style="margin-top: 15px; font-size: 17px;" [style.color]="data.primaryColor ? data.primaryColor : '#009cd7'">
                Podra obtener el beneficio presentando en la tienda el QR enviado por mail.
              </span>
            </h3>

            <!-- Link, código, barcode, etc --> 
            <h3 *ngIf="barcode;else noBarcode">
              <span style="margin-top: 15px; font-size: 17px;" [style.color]="data.primaryColor">
                Para realizar la compra con descuento utilizá el siguiente código: <br>
                <img src="{{barcode}}" alt="">
              </span>
            </h3>  

            <ng-template #noBarcode>
            <h3
              style="margin-top: 15px; font-size: 17px; overflow-wrap: break-word;word-wrap: break-word;" 
              [style.color]="data.primaryColor"
              *ngIf="data.coupons.tipo == 'eshop' && data.coupons.nombre != 'SORTEO'; else normalCodigo"
            >
              {{ "coupon.add_discount" | translate }} <br />

              <div
                *ngIf="
                  data.coupons.codigo_eshop;
                  else normalEshop
                "
              >
                <a *ngIf="!descuento_codigo_eshop && data.coupons.codigo_eshop.includes('http')"
                  [href]="data.coupons.codigo_eshop"
                  target="_blank"
                  style="color: #2f3068; font-weight: 600"
                  >{{ data.coupons.codigo_eshop }}</a
                >
                <a *ngIf="!data.coupons.codigo_eshop.includes('http') && !data.coupons.url_eshop"
                  [href]="web()"
                  target="_blank"
                  style="color: #2f3068; font-weight: 600"
                  >{{ data.coupons.local_nombre }}</a
                >
                <a *ngIf="!data.coupons.codigo_eshop.includes('http') && data.coupons.url_eshop"
                  [href]="web()"
                  target="_blank"
                  style="color: #2f3068; font-weight: 600"
                  >{{ data.coupons.url_eshop }}</a
                >
                <span style="font-size: 17px;" [style.color]="data.primaryColor" 
                  *ngIf="!descuento_codigo_eshop && !data.coupons.codigo_eshop.includes('http')">
                  {{ "coupon.use_code" | translate }}<br />
                  <span style="font-size: 20px; font-weight: 600;">
                    {{ data.coupons.codigo_eshop }}
                  </span>
                </span>
                <span style="font-size: 17px;" [style.color]="data.primaryColor" 
                  *ngIf="descuento_codigo_eshop && !data.coupons.codigo_eshop.includes('http')">
                  {{ "coupon.use_code" | translate }}<br />
                  <span style="font-size: 20px; font-weight: 600;">
                    {{ descuento_codigo_eshop }}
                  </span>
                </span>
              </div>
              <ng-template #normalEshop>
                <a
                  [href]="web()"
                  target="_blank"
                  style="color: #2f3068; font-weight: 600"
                  >{{ data.coupons.local_nombre }}</a
                >
                {{ "coupon.use_code" | translate }}<br />
                {{ descuento_codigo_eshop }}
              </ng-template>
            </h3>
            </ng-template>

            <ng-template *ngIf="data.coupons.nombre != 'SORTEO'" #normalCodigo>
              <h3 [style.color]="data.primaryColor">
                {{ "coupon.only_enable_locals" | translate }}
              </h3>
            </ng-template>

            <!-- Iconos social media -->
            <div class="share mt-3 justify-content-center">
              <div class="share-box" (click)="share('F')" [style.backgroundColor]="data.primaryColor">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 32 32"
                  version="1.1"
                  role="img"
                  aria-labelledby="at-svg-facebook-1"
                  class="at-icon at-icon-facebook"
                  style="fill: rgb(255, 255, 255); width: 20px; height: 20px"
                >
                  <title id="at-svg-facebook-1">Facebook</title>
                  <g>
                    <path
                      d="M22 5.16c-.406-.054-1.806-.16-3.43-.16-3.4 0-5.733 1.825-5.733 5.17v2.882H9v3.913h3.837V27h4.604V16.965h3.823l.587-3.913h-4.41v-2.5c0-1.123.347-1.903 2.198-1.903H22V5.16z"
                      fill-rule="evenodd"
                    ></path>
                  </g>
                </svg>
              </div>
              <div class="share-box" (click)="share('T')" [style.backgroundColor]="data.primaryColor">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 32 32"
                  version="1.1"
                  role="img"
                  aria-labelledby="at-svg-twitter-2"
                  class="at-icon at-icon-twitter"
                  style="fill: rgb(255, 255, 255); width: 20px; height: 20px"
                >
                  <title id="at-svg-twitter-2">Twitter</title>
                  <g>
                    <path
                      d="M27.996 10.116c-.81.36-1.68.602-2.592.71a4.526 4.526 0 0 0 1.984-2.496 9.037 9.037 0 0 1-2.866 1.095 4.513 4.513 0 0 0-7.69 4.116 12.81 12.81 0 0 1-9.3-4.715 4.49 4.49 0 0 0-.612 2.27 4.51 4.51 0 0 0 2.008 3.755 4.495 4.495 0 0 1-2.044-.564v.057a4.515 4.515 0 0 0 3.62 4.425 4.52 4.52 0 0 1-2.04.077 4.517 4.517 0 0 0 4.217 3.134 9.055 9.055 0 0 1-5.604 1.93A9.18 9.18 0 0 1 6 23.85a12.773 12.773 0 0 0 6.918 2.027c8.3 0 12.84-6.876 12.84-12.84 0-.195-.005-.39-.014-.583a9.172 9.172 0 0 0 2.252-2.336"
                      fill-rule="evenodd"
                    ></path>
                  </g>
                </svg>
              </div>
              <div class="share-box" (click)="share('W')" [style.backgroundColor]="data.primaryColor">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 32 32"
                  version="1.1"
                  role="img"
                  aria-labelledby="at-svg-whatsapp-3"
                  class="at-icon at-icon-whatsapp"
                  style="fill: rgb(255, 255, 255); width: 20px; height: 20px"
                >
                  <title id="at-svg-whatsapp-3">WhatsApp</title>
                  <g>
                    <path
                      d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                      fill-rule="evenodd"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>

          <!-- Texto: aclaraciones sobre el cupón -->
          <div class="separador"></div>
          <div style="text-align: center">
            <span class="borde-inferior" style="color: #313131; font-size: 14px"
              >{{ "coupon.print_coupon" | translate }}
              <span style="color: #313131; font-size: 14px" *ngIf="data.coupons.tipo != 'eshop'"> {{'coupon.how_to_use_coupon'| translate}} </span><br />
              {{ "coupon.if_print_coupon" | translate }}

              <a
                href="javascript:window.print()"
                class="btn-impresion"
                style="color: #313131 !important; font-weight: bold !important"
                >{{ "coupon.here" | translate }}</a
              ><br /><br />
              {{ "coupon.email_send" | translate }}<br /><br />
            </span>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>
