import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { FilterDialog } from 'src/app/public/coupons/filter/filter.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  @Output() menu: EventEmitter<boolean> = new EventEmitter()

  showAlax = true;
  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private route: Router
  ) {
    if (!environment.marketplace) {
      this.showAlax = false;
    } 
  }

  ngOnInit(): void {
  }
  logged() {
    return (localStorage.getItem('token') && !localStorage.getItem('isClient'));
  }

  getUsername() {
    const name = localStorage.getItem('name');
    return name ? name : '';
  }
  logout() {
    this.authService.logout();
  }
  openMenu() {
    this.menu.emit(true);
  }

  openFilter(): void {
    const dialogRef = this.dialog.open(FilterDialog, {
      panelClass: ['dialog', 'common'],
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.route.navigate([
        '/cupones-descuentos/' +
          (result.categories == '' ? 'todos' : result.categories) +
          '/' +
          (result.types == '' ? 'todos' : result.types) +
          '/' +
          (result.district == '' ? 'todos' : result.district) +
          '/' +
          (result.text == '' ? 'todos' : result.text),
      ]);
    });
  }
}
