import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { environment as env, environment } from '../environments/environment';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth/auth.service';
import { LangService } from './shared/lang.service';
import { Meta } from '@angular/platform-browser';
import { PublicService } from './public/public.service';
import { LoadingService } from './shared/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') public sidenav: MatSidenav;
  mobileQuery: MediaQueryList;
  isOpen: boolean;
  whatsapp: any = null;
  title = 'cuponeala';
  user = true;
  customerLogin: string = '';

  provinces: any;
  district: any;
  categories: any = null;
  routeUrl: string;

  showAlax = false;
  private _mobileQueryListener: () => void;
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public route: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private langService: LangService,
    private metaTagService: Meta,
    private publicService: PublicService,
    public loadingService : LoadingService
  ) {
    if (!environment.marketplace) {
      this.showAlax = true;
    } 
    this.mobileQuery = media.matchMedia('(max-width: 767px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.translate.use(this.langService.globalLang);
    this.route.events.subscribe(() => (
      this.routeUrl = this.route.url
      ));
  }

  ngOnInit() {
    this.customerLogin = environment.customerUrl.login;

    this.getCategories();
    this.getProvince();
    this.getNeighbourhoods();
    this.route.events.subscribe((event) => {
      this.sidenav.close();
    });

    let title = 'Cuponeala';
    let desc =
      'Todos los descuentos en un solo lugar. Ahorra más pagando menos!';
    let img = 'https://alax.tech/assets/services/images/alax-logo-big.png';
      //'https://www.cuponeala.com/assets/images/home/banners/mobile.jpg';
    this.metaTagService.updateTag({ property: 'name', content: title });
    this.metaTagService.updateTag({ property: 'description', content: desc });
    this.metaTagService.updateTag({
      property: 'og:image',
      itemprop: 'image',
      content: img,
    });
    this.metaTagService.updateTag({
      property: 'og:description',
      content: desc,
    });
    this.metaTagService.updateTag({
      property: 'og:url',
      content: 'https://alax.tech',
    });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({
      property: 'og:site_name',
      content: 'https://alax.tech',
    });
    this.metaTagService.updateTag({
      property: 'og:updated_time',
      content: '1643988095',
    });
    //TWITTER
    this.metaTagService.updateTag({
      property: 'twitter:title',
      content: 'Cuponeala',
    });
    this.metaTagService.updateTag({
      property: 'twitter:description',
      content: desc,
    });
    this.metaTagService.updateTag({
      property: 'twitter:image',
      itemprop: 'image',
      content: img,
    });
    this.metaTagService.updateTag({
      property: 'twitter:image:secure_url',
      itemprop: 'image',
      content: img,
    });
    this.metaTagService.updateTag({
      property: 'twitter:url',
      content: 'https://alax.tech',
    });
    this.metaTagService.updateTag({
      property: 'twitter:type',
      content: 'website',
    });
    this.metaTagService.updateTag({
      property: 'twitter:site_name',
      content: 'https://alax.tech',
    });

    if (environment.botmaker && !window.location.pathname.includes('landing')) {
     
      // chatbot
      let js = document.createElement('script');
      js.type = 'text/javascript';
      js.async = true;
      js.src = `https://go.botmaker.com/rest/webchat/p/${environment.botmaker}/init.js`;
      //document.body.appendChild(js);

      // whatsapp
      this.whatsapp = environment.whatsapp;
      document.body.appendChild(this.whatsapp);
    } else if (environment.whatsapp ) {
      // whatsapp      
      this.whatsapp = environment.whatsapp;
      document.body.appendChild(this.whatsapp);
    }

    /* try {
      //Documentation https://developers.amplitude.com/docs/javascript
      const email = localStorage.getItem('email');
      let amplitudeUser = email ? email : null;
      amplitude
        .getInstance()
        .init(env.amplitudeInstance, amplitudeUser, { includeReferrer: true });
      amplitude.setUserProperty('type', 'Consumer');
    } catch (er) {} */
  }

  hasMatches(...values: string[]): boolean {
    let matchFound: boolean = false;
    if (this.routeUrl) {
      for (let i = 0; i < values.length; i++) {
        if (this.routeUrl.indexOf(values[i]) > -1) {
          matchFound = true;
          break;
        }
      }
    }

    return matchFound;
  }


  goToCustomer(){
    if (environment.production) {
      window.location.href = this.customerLogin;
    }else{
      this.route.navigate(['/clientes/login']);
    }
    
  }
  logged() {
    if (localStorage.getItem('token') != null) {
      return true;
    } else {
      return false;
    }
  }

  getUsername() {
    const name = localStorage.getItem('name');
    return name ? name : '';
  }

  getClick(event) {
    this.isOpen = event;
    if (this.isOpen) {
      this.sidenav.open();
    } else {
      this.sidenav.close();
    }
  }

  logout() {
    this.authService.logout();
  }

  getCategories() {
    let storedCat = sessionStorage.getItem('categories');
    if (storedCat) {
      this.categories = JSON.parse(storedCat);
    } else {
      this.categories = this.publicService.getCategories().subscribe(
        (result) => {
          sessionStorage.setItem('categories', JSON.stringify(result));
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  getProvince() {
    let storedProvinces = sessionStorage.getItem('provinces');
    if (storedProvinces) {
      this.provinces = JSON.parse(storedProvinces);
    } else {
      this.publicService.getProvince().subscribe((result) => {
        sessionStorage.setItem('provinces', JSON.stringify(result));
      });
    }
  } 

  getNeighbourhoods() {
    let storedNeighbourhoods = sessionStorage.getItem('neighbourhoods');
    if (storedNeighbourhoods) {
      this.district = JSON.parse(storedNeighbourhoods);
    } else {
      this.publicService.getLocalNeighbourhoods().subscribe((result) => {
        sessionStorage.setItem('neighbourhoods', JSON.stringify(result));
      });
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
